import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";


import blackplastic from "../images/hardware/blackplastic.png";
import brickforce from "../images/hardware/brickforce.png";
import dampproof from "../images/hardware/dampproof.png";
import impermo from "../images/hardware/Impermo.png";
import termitepoison from "../images/hardware/termite poison.png";


function HardwarePage() {
    return (
      <Layout>
        <SEO
          title="Building"
          keywords={[`building`, `cement`, `Zimbabwe`, `bricks`]}
        />
  
       
            <div class="rounded overflow-hidden">
              <img src={blackplastic}  alt="Sunset in the mountains"/>
               
               <div class="font-bold text-xl mb-2">Black Plastic Sheets</div>
               <p class="text-gray-700 text-base">
               
               </p>
               </div >
               
              
              <div class = "rounded overflow-hidden"> <img src={brickforce}  alt="Sunset in the mountains"/></div>
               <div class="font-bold text-xl mb-2">Brickforce</div>
               <p class="text-gray-700 text-base">
               
               </p>
            
             
        
          
            <div class="rounded overflow-hidden">
              <img src={dampproof}  alt="Sunset in the mountains"/>
              
               <div class="font-bold text-xl mb-2">Damp Proof</div>
               <p class="text-gray-700 text-base">
               
               </p>
             </div>
             <div class = "rounded overflow-hidden"> <img src={impermo}  alt="Sunset in the mountains"/></div>
               <div class="font-bold text-xl mb-2">Brickforce</div>
               <p class="text-gray-700 text-base">
               
               </p>
            

             


        
           
          
            <div class="rounded overflow-hidden">
              <img src={termitepoison}  alt="Sunset in the mountains"/>
             
               <div class="font-bold text-xl mb-2">Termite Poison</div>
               <p class="text-gray-700 text-base">
               
               </p>
               </div>
               
            
        
         
               <h4 className="bg-yellow-400 text-2xl font-bold inline-block my-8 p-3">
          Call or WhatsApp: +263 774 500 868 or +263 773 386 695
        </h4>  
         
        
      </Layout>
    );
  }
  
  export default HardwarePage;
  